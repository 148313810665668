<template>
  <ion-content>
    <div class="site-content">
      <div class="landing-banner" style="background-color: #56B35A;">
        <div class="container">
          <div class="lb-info">
            <h2 style="color: #ffffff">Scrapl.us Veri muhafaza ve yok etme</h2>
            <p style="color: #ffffff" class="" v-html="contactContent">
            </p>
            <div style="color: #ffffff" class="lb-button" v-html="customerPhone">
            </div>
          </div><!-- .lb-info -->
        </div>
      </div><!-- .landing-banner -->
    </div>
  </ion-content>


</template>

<script>
import {inject, ref} from "@vue/runtime-core";
import {IonContent} from "@ionic/vue";

export default {
  name: "About Us",
  components: {IonContent},
  setup() {
    const $api = inject('api');
    const contactContent = ref('');
    const customerPhone = ref('');
    contactContent.value = $api.cacheSettings.remove.data;
    customerPhone.value = $api.cacheSettings.customer_phone.data;
    return {
      contactContent,
      customerPhone
    }
  },
}
</script>

<style scoped>

</style>